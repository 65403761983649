export const EDUCATION = [
  {
    degree: "Master of Science (MS), Software Engineering",
    school: "San José State University",
    location: "San José, CA",
    date: "May 2023",
    gpa: 3.92,
    categories: ["Full-Stack", "Mobile", "Machine Learning"],
    languages: ["Python", "Java", "JavaScript", "TypeScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: ["React", "React Native", "Bootstrap", "Flask"],
    tools: ["Git", "Linux", "Docker", "MongoDB", "AWS"],
    machineLearning: ["PyTorch", "TensorFlow", "Keras", "scikit-learn", "NumPy", "pandas"],
  },
  {
    degree: "Bachelor of Science (BS), Computer Science",
    school: "San José State University",
    location: "San José, CA",
    date: "May 2021",
    gpa: 3.91,
    categories: ["Full-Stack", "Game"],
    languages: ["Python", "Java", "JavaScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git", "Linux", "PostgreSQL", "MySQL", "MongoDB", "GCP"],
    machineLearning: [],
  },
];

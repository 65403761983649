import bjjPredictorImg from "../images/bjj_predictor.jpg";
import connect4aiImg from "../images/connect4_ai.jpg";
import cryptoOnlineImg from "../images/crypto_online.jpg";
import digitRecognizerImg from "../images/digit_recognizer.jpg";
import donutDashImg from "../images/donut_dash.jpg";
import iconImg from "../images/icon.jpg";
import infiniteMonkeyImg from "../images/infinite_monkey.jpg";
import kaggleFeedbackImg from "../images/kaggle_feedback.png";
import kagglePatentImg from "../images/kaggle_patent.png";
import officeSupplyDepotImg from "../images/office_supply_depot.jpg";
import polynomialRegressionImg from "../images/polynomial_regression.jpg";
import skinDetectorImg from "../images/skin_detector.jpg";

const ProjectData = [
  {
    image: iconImg,
    title: "Personal Website",
    date: "Oct 2019 – Present",
    description: ["Developed portfolio website using React"],
    categories: [],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git", "GCP"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/ryantran2165.github.io/tree/source",
    demo: "",
  },
  {
    image: kaggleFeedbackImg,
    title: "Feedback Prize - Predicting Effective Arguments (Kaggle&nbsp;Competition)",
    date: "May 2022 – Aug 2022",
    description: [
      "Trained BERT models to predict effectiveness of argumentative essays using Transformers",
      "Placed 31st out of 1,557 competitors (top 2%)",
    ],
    categories: ["Machine Learning", "Competition"],
    languages: ["Python"],
    frameworksLibraries: [],
    tools: ["Git"],
    machineLearning: ["PyTorch", "scikit-learn", "Transformers", "NumPy", "pandas"],
    source: "",
    demo: "https://www.kaggle.com/certification/competitions/ryantran2165/feedback-prize-effectiveness",
  },
  {
    image: kagglePatentImg,
    title: "U.S. Patent Phrase to Phrase Matching (Kaggle&nbsp;Competition)",
    date: "Mar 2022 – Jun 2022",
    description: [
      "Trained BERT models to predict semantic similarity between patent phrases using Transformers",
      "Placed 60th out of 1,889 competitors (top 3.2%)",
    ],
    categories: ["Machine Learning", "Competition"],
    languages: ["Python"],
    frameworksLibraries: [],
    tools: ["Git"],
    machineLearning: ["PyTorch", "scikit-learn", "Transformers", "NumPy", "pandas"],
    source: "",
    demo: "https://www.kaggle.com/certification/competitions/ryantran2165/us-patent-phrase-to-phrase-matching",
  },
  {
    image: skinDetectorImg,
    title: "Skin Detector (DEFUNCT)",
    date: "Sep 2021 – Dec 2021",
    description: [
      "Developed skin condition detector mobile app using React Native",
      "Trained convolutional neural networks using TensorFlow",
      "Deployed on-device models using TensorFlow.js",
    ],
    categories: ["Mobile", "Machine Learning"],
    languages: ["Python", "TypeScript"],
    frameworksLibraries: ["React Native"],
    tools: ["Git"],
    machineLearning: ["TensorFlow", "Keras", "scikit-learn", "NumPy", "pandas"],
    source: "https://github.com/sjsucmpe272-fall21/SkinDetector",
    demo: "",
  },
  {
    image: connect4aiImg,
    title: "Connect 4 AI",
    date: "Aug 2020 – Dec 2020",
    description: [
      "Developed Connect 4 AI web app using React",
      "Trained Q-network using self-play reinforcement learning",
    ],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: ["TensorFlow"],
    source: "https://github.com/ryantran2165/connect4-ai",
    demo: "https://www.ryanlongtran.com/connect4-ai",
  },
  {
    image: officeSupplyDepotImg,
    title: "Office Supply Depot (DEFUNCT)",
    date: "Oct 2020 – Dec 2020",
    description: [
      "Led team of 3 to develop full-stack e-commerce website using React and Django",
      "Optimized hypothetical package delivery routes using GCP Maps and Directions APIs",
    ],
    categories: ["Full-Stack"],
    languages: ["Python", "JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git", "PostgreSQL", "GCP"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/office-supply-depot-frontend",
    demo: "",
  },
  {
    image: bjjPredictorImg,
    title: "BJJ Predictor (DEFUNCT)",
    date: "Jun 2020 – Aug 2020",
    description: [
      "Developed full-stack Brazilian jiu-jitsu match predictor web app with 70% accuracy using React and Flask",
      "Trained neural network using TensorFlow",
      "Deployed using AWS DynamoDB and Heroku",
    ],
    categories: ["Full-Stack", "Machine Learning"],
    languages: ["Python", "JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap", "Flask"],
    tools: ["Git", "Linux", "AWS"],
    machineLearning: ["TensorFlow", "Keras", "scikit-learn", "NumPy", "pandas"],
    source: "https://github.com/ryantran2165/bjj-predictor",
    demo: "",
  },
  {
    image: digitRecognizerImg,
    title: "Digit Recognizer",
    date: "Jan 2020 – Aug 2020",
    description: [
      "Developed handwritten digit recognizer web app with 90% accuracy using React",
      "Implemented feedforward and convolutional neural networks from scratch",
    ],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/digit-recognizer",
    demo: "https://www.ryanlongtran.com/digit-recognizer",
  },
  {
    image: donutDashImg,
    title: "Donut Dash",
    date: "Mar 2020 – May 2020",
    description: ["Developed 2D platformer game using C#"],
    categories: ["Game"],
    languages: ["C#"],
    frameworksLibraries: [],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/donut-dash",
    demo: "https://ryantran2165.itch.io/donut-dash",
  },
  {
    image: polynomialRegressionImg,
    title: "Polynomial Regression",
    date: "May 2020 – May 2020",
    description: ["Developed polynomial regression visualizer web app using React"],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/polynomial-regression",
    demo: "https://www.ryanlongtran.com/polynomial-regression",
  },
  {
    image: cryptoOnlineImg,
    title: "Crypto Online (DEFUNCT)",
    date: "Jan 2020 – Jan 2020",
    description: ["Developed full-stack cryptography web app using PHP"],
    categories: ["Full-Stack"],
    languages: ["JavaScript", "HTML", "CSS", "SQL"],
    frameworksLibraries: ["Bootstrap"],
    tools: ["Git", "MySQL"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/crypto-online",
    demo: "",
  },
  {
    image: infiniteMonkeyImg,
    title: "Infinite Monkey",
    date: "Dec 2019 – Dec 2019",
    description: ["Developed genetic algorithm visualizer web app using React"],
    categories: ["Machine Learning"],
    languages: ["JavaScript", "HTML", "CSS"],
    frameworksLibraries: ["React", "Bootstrap"],
    tools: ["Git"],
    machineLearning: [],
    source: "https://github.com/ryantran2165/infinite-monkey",
    demo: "https://www.ryanlongtran.com/infinite-monkey",
  },
];

export default ProjectData;
